import { useContext, useEffect, useState } from "react"
import Link from "next/link"
import Icons from "lib/components/Icons"
import Text from "lib/components/Text"
import { useRouter } from "next/router"
import { LanguageContext } from "context/LanguageContext"
import { handleChar } from "lib/helpers"
import { Tooltip } from "react-tooltip"

export interface itemNavigation {
  url?: string
  name: string
  icon?: string
  tourName?: string
  iconOnly?: boolean
  withIcon?: boolean
  children?: {
    name: string
    url: string
  }[]
}

interface WrapperItemProps {
  children: React.ReactNode
  anyChildItem: boolean
  url: string
  pathName: string
  showChildren: boolean
  setShowChildren: () => void
  dataChildren: any
}

function NavigationChildren({ dataChildren, pathName, showChildren }) {
  const { useTranslation: t } = useContext(LanguageContext)
  let heightChildren = ""

  if (dataChildren.length === 4) {
    heightChildren = "h-64"
  }
  return (
    <div
      className={`w-full px-4 transition-height duration-300 transform ${
        (showChildren) ? `visible ${heightChildren}` : "invisible h-0"
      }`}>
      {dataChildren.map((itemChildren, indexItemChildren) => (
        <div className="my-4" key={indexItemChildren}>
          <Link href={itemChildren.url} legacyBehavior>
            <a>
              <div
                className={`h-inherit hover:text-primary hover:border-primary p-2 focus:border-blue-700 border pl-7 rounded-md navItem ${
                  itemChildren.url === pathName
                    ? "border-primary bg-primary text-white hover:bg-transparent"
                    : "border-transparent text-default"
                }`}>
                <div className="relative flex">
                  <Text size="small" customClass="font-medium truncate">
                    {t(
                      `${handleChar({ value: itemChildren.name, replacement: "_" }).toLowerCase()}`,
                    )}
                  </Text>
                  {itemChildren.badge && (
                    <div className="-ml-1 inline-flex justify-center items-center w-2.5 h-2.5 text-xs font-bold text-white bg-red-500 rounded-full" />
                  )}
                </div>
              </div>
            </a>
          </Link>
        </div>
      ))}
    </div>
  )
}

function WrapperItem({
  children,
  anyChildItem = false,
  url = "#",
  pathName = "/",
  showChildren = false,
  dataChildren = [],
  setShowChildren,
}: WrapperItemProps) {
  if (anyChildItem) {
    return (
      <>
        <div
          aria-label="item"
          role="button"
          tabIndex={0}
          className="w-full h-inherit"
          onClick={setShowChildren}>
          {children}
        </div>

        <NavigationChildren
          dataChildren={dataChildren}
          showChildren={showChildren}
          pathName={pathName}
        />
      </>
    )
  }

  return (
    <Link href={url} legacyBehavior>
      <a className="w-full h-inherit">{children}</a>
    </Link>
  )
}

function NavigationItem({ url, icon, name, tourName, iconOnly = false, children, withIcon = true }: itemNavigation) {
  const { useTranslation: t } = useContext(LanguageContext)
  const router = useRouter()
  const [showChildren, setShowChildren] = useState(true)
  let isActiveItem = false

  useEffect(() => {
    const itemChildren = children.find((item) => item.url === router.pathname)
    if (itemChildren && itemChildren.url) {
      setShowChildren(true)
    }
  }, [children, router])

  if (router.pathname === url) {
    isActiveItem = true
  }

  return (
    <div className="h-full relative flex items-center flex-col">
      <WrapperItem
        anyChildItem={children && children.length > 0}
        url={url}
        showChildren={showChildren}
        dataChildren={children}
        pathName={router.pathname}
        setShowChildren={() => {
          setShowChildren((currentItem) => !currentItem)
        }}>
        <div
          className={`h-inherit hover:text-primary hover:border-primary py-2 focus:border-blue-700 border mx-2 rounded-md navItem truncate text-ellipsis overflow-hidden ${tourName} ${
            isActiveItem
              ? "border-primary bg-primary text-white hover:bg-transparent"
              : "border-transparent text-default"
          }`}>
          <div className="flex h-inherit relative items-center mx-2">
            <div className="flex flex-row items-center truncate" id={tourName === "training-management-services" ? `tooltip-${tourName}` : ""}>
              {withIcon &&
                <div className="mr-2">
                  <Icons iconName={icon} />
                </div>
              }
              {iconOnly && (
                <Text customClass="text-[14px] font-medium text-overflow-ellipsis truncate">
                  {t(`${handleChar({ value: name, replacement: "_" }).toLowerCase()}`)}
                </Text>
              )}
            </div>
            {children && children.length > 0 && (
              <div className="relative right-0">
                {iconOnly && (
                  <Icons
                    iconName="arrow-right"
                    width="20"
                    height="20"
                    styles={`transform ${
                      showChildren ? "rotate-90 duration-500" : "rotate-0 duration-500"
                    }`}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </WrapperItem>
      <Tooltip anchorSelect={`#tooltip-${tourName}`} place="top">
        {name}
      </Tooltip>
    </div>
  )
}

NavigationItem.defaultProps = {
  children: [],
}

export default NavigationItem
