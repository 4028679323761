import NavigationItem from "@components/Layouts/Navigation/Item"
import { GetBadgeData } from "@graphql/homepage/get_badge_data"
import fetcher from "lib/fetcher"
import useWindowSize from "hooks/useWindowSize"
import Button from "lib/components/Button"
import Icons from "lib/components/Icons"
import { useAuth } from "lib/auth"
import { useContext, useMemo, useState } from "react"
import Drawer from "react-modern-drawer"
import useSWR from "swr"
import { DrawerContext } from "context/DrawerContext"

interface DataNavigationItem {
  url: string
  name: string
  icon: string
  tourName: string
  roles: Array<string>
  children?: {
    name: string
    url: string
    badge?: boolean
  }[]
}

export interface NavigationProps {
  dataNavigation: DataNavigationItem[]
  isDrawer?: boolean
  iconOnly?: boolean
  handleLogout?: () => void
}

function Navigation({ dataNavigation, isDrawer, iconOnly, handleLogout }: NavigationProps) {
  const { toggleDrawer, drawer } = useContext(DrawerContext)
  const { userProfile } = useAuth()
  const [width] = useWindowSize()
  const isDesktop = width >= 600
  const [badgeDataUrl, setBadgeDataUrl] = useState(null)
  const { data: dataBadge } = useSWR<GetBadgeData>(badgeDataUrl, fetcher)

  useMemo(() => {
    if (userProfile?.session_id) {
      setBadgeDataUrl(
        `/api/homepage/get_badge_data?user_id=${userProfile?.user_id}&user_role=${userProfile?.user_role}`,
      )
    }

    return () => {
      setBadgeDataUrl(null)
    }
  }, [userProfile])

  const resultNavigation = dataNavigation.map((item) => {
    if (item.children?.length > 0) {
      return {
        ...item,
        children: item.children.map((child) => {
          switch (child.url) {
            case "/my-classes":
              return {
                ...child,
                badge: dataBadge?.data.class_count.aggregate.count > 0,
              }
            case "/orders":
              return {
                ...child,
                badge: dataBadge?.data.order_count.aggregate.count > 0,
              }
            default:
              return child
          }
        }),
      }
    }

    if (item.url === "/inhouse-proposal") {
      return {
        ...item,
        badge: dataBadge?.data.proposal_count?.aggregate.count > 0,
      }
    }
    return item
  })
  if (isDrawer) {
    return (
      <Drawer open={drawer} onClose={toggleDrawer} direction="left">
        <div className="flex flex-col overflow-scroll mt-12 p-2 sm:p-8">
          {resultNavigation.map((navigation, indexNavigation) => {
            if (navigation.roles.includes(userProfile?.user_role)) {
              return (
                <div key={indexNavigation} className="my-2">
                  <NavigationItem {...navigation} iconOnly={iconOnly} />
                </div>
              )
            }
            return false
          })}
          <div className="my-2">
            <NavigationItem url="/logout" icon="logout" name="Logout" tourName="/logout" />
          </div>
        </div>
      </Drawer>
    )
  }

  return (
    <div className="mt-2">
      {resultNavigation.map((navigation, indexNavigation) => {
        if (navigation.roles.includes(userProfile?.user_role)) {
          return (
            <div key={indexNavigation} className="my-2">
              <NavigationItem {...navigation} iconOnly={iconOnly} />
            </div>
          )
        }
        return false
      })}
      {!isDesktop && (
        <div className="my-4">
          <div className="flex h-inherit relative items-center mx-4">
            <div className="mr-5">
              <Icons iconName="logout" />
            </div>
            <Button variant="text" size="large" color="default" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Navigation
