function EvaluationLayout({ children }) {
  return (
    <div className="relative min-h-screen">
      <div>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default EvaluationLayout
